import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { context } from "../../App";

//MATERIAL UI
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//REQUEST
import Constant from "../../Config/Constant";

const Login = () => {
  const navigate = useNavigate();

  const [isAuth, setIsAuth] = useContext(context);
  const [Username, setUsername] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Login = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (Username === null || Username === "") {
      setIsLoading(false);
      setAlertMessage("Please Enter Username.");
      setSeverity("error");
      setOpen(true);
    } else if (Password === null || Password === "") {
      setIsLoading(false);
      setAlertMessage("Please Enter Password.");
      setSeverity("error");
      setOpen(true);
    } else {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let formData = new FormData();
      formData.append("Username", Username);
      formData.append("Password", Password);

      axios
        .post(`${Constant.serverlink}/api/Account/Login`, formData, config)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setIsAuth(true);
            localStorage.setItem("access-token", response.data.token);
            navigate("/cpanel/header");
          } else {
            setIsLoading(false);
            setAlertMessage("Invalid Username or Password.");
            setSeverity("error");
            setOpen(true);
          }
        }).catch(() => {
          setIsLoading(false);
            setAlertMessage("Invalid Username or Password.");
            setSeverity("error");
            setOpen(true);
        });
    }
  };

  return (
    <div className="loginContent">
      <Paper elevation={3} className="paper">
        <h1>Login</h1>
        <Divider />
        <br />
        <TextField
          required
          id="outlined-required"
          label="UserName"
          value={Username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <br />
        <TextField
          required
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" onClick={Login}>
          Login
        </Button>
      </Paper>

      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
