import axios from "axios";
import Constant from "./Constant";

var BearerToken = localStorage.getItem("access-token");

class Request {
    constructor() {
      this.api = axios.create({
        baseURL: Constant.serverlink,
        timeout: Constant.timeout,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${BearerToken}`,
        },
      });
    }
  
    debugit() {
      this.api.interceptors.request.use((request) => {
        console.log("Starting Request", request);
        return request;
      });
  
      this.api.interceptors.response.use((response) => {
        console.log("Response:", response);
        return response;
      });
    }

    async getHeader() {
      const link = "/api/Header/GetHeader";
      return await this.api.post(link);
    }

    async getStory() {
      const link = "/api/Story/GetStory";
      return await this.api.post(link);
    }

    async GetCategory() {
      const link = "/api/Category/GetCategory";
      return await this.api.post(link);
    }

    async deleteCategory(id) {
      const link = "/api/Category/Delete?id=" + id;
      return await this.api.post(link);
    }

    async GetProducts(id) {
      const link = "/api/Products/GetProducts"+id;
      return await this.api.post(link);
    }

    async deleteProducts(id) {
      const link = "/api/Products/Delete?id=" + id;
      return await this.api.post(link);
    }

    async getProductsByCategory(categoryId) {
      const link = `/api/Products/getProductsByCategory/${categoryId}`;
      return await this.api.get(link);
    }
  

    async getLocation() {
      const link = "/api/Location/GetLocation";
      return await this.api.post(link);
    }

    async deleteLocation(id) {
      const link = "/api/Location/Delete?id=" + id;
      return await this.api.post(link);
    }
}

export default Request;