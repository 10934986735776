import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import AdminAppBar from "../Admin/AdminAppBar";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import Constant from "../../Config/Constant";

const Profile = () => {
  const [OldPassword, setOldPassword] = React.useState("");
  const [NewPassword, setNewPassword] = React.useState("");
  const [ConfirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const save = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (OldPassword === null || OldPassword === "") {
      setIsLoading(false);
      setAlertMessage("Please Enter Your Old Password.");
      setSeverity("error");
      setOpen(true);
    } else if (NewPassword === null || NewPassword === "") {
      setIsLoading(false);
      setAlertMessage("Please Enter Your New Password.");
      setSeverity("error");
      setOpen(true);
    } else if (ConfirmNewPassword !== NewPassword) {
      setIsLoading(false);
      setAlertMessage(
        "New Password and Confirm New password should be equals."
      );
      setSeverity("error");
      setOpen(true);
    } else {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      let formData = new FormData();
      formData.append("OldPassword", OldPassword);
      formData.append("NewPassword", NewPassword);
      formData.append("ConfirmNewPassword", ConfirmNewPassword);

      axios
        .post(
          `${Constant.serverlink}/api/Account/ChangePassword`,
          formData,
          config
        )
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
            setAlertMessage("Your Password has been changed.");
            setSeverity("success");
            setOpen(true);
          } else {
            setIsLoading(false);
            setAlertMessage("Incorrect Password.");
            setSeverity("error");
            setOpen(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setAlertMessage("Incorrect Password.");
          setSeverity("error");
          setOpen(true);
        });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AdminAppBar />
      <div className="backgroundContent">
        <TextField
          required
          id="outlined-password-input"
          label="Old Password"
          type="password"
          autoComplete="current-password"
          value={OldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <br />
        <br />
        <TextField
          required
          id="outlined-password-input"
          label="New Password"
          type="password"
          autoComplete="current-password"
          value={NewPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <br />
        <br />
        <TextField
          required
          id="outlined-password-input"
          label="Confirm Password"
          type="password"
          autoComplete="current-password"
          value={ConfirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" onClick={save}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
