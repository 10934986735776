import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AdminAppBar from "../Admin/AdminAppBar";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import axios from "axios";

//REQUEST
import Request from "../../Config/Request";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import Constant from "../../Config/Constant";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const Background = () => {
  const request = new Request();

  const [Header, setHeader] = React.useState();
  const [files, setFiles] = React.useState([]);
  const [Title, setTitle] = React.useState("");
  const [Image, setImage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    getHeader();
  }, []);

  const getHeader = async () => {
    setIsLoading(true);
    const response = await request.getHeader();
    setHeader(response.data);
    setImage(response.data.image);
    setTitle(response.data.title);
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const save = async () => {
    if (Title === null || Title === "") {
      setAlertMessage("Please Enter Title.");
      setSeverity("error");
      setOpen(true);
    } else {
      setIsLoading(true);
      var BearerToken = localStorage.getItem("access-token");

      const config = {
        headers: {
          Authorization: `Bearer ${BearerToken}`,
          'Content-Type': 'multipart/form-data'
        },
      };

      let formData = new FormData();
      for (let key in files) {
        formData.append("File", files[key].file);
      }
      formData.append("HeaderId", Header.headerId);
      formData.append("Title", Title);
      axios
        .post(
          `${Constant.serverlink}/api/Header/Header`,
          formData,
          config,
        )
        .then((response) => {
          if (response.status === 200) {
            setAlertMessage("Hader has been updated.");
            setSeverity("success");
            setOpen(true);
            setFiles([]);
            getHeader();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong. Please try again.");
            setSeverity("error");
            setOpen(true);
          }
        });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AdminAppBar />
      <img
        src={`${Constant.serverlink}${Image}`}
        className="backgroundContentImage"
      />
      <div className="backgroundContent">
        <FilePond
          files={files}
          allowReorder={true}
          allowMultiple={false}
          onupdatefiles={setFiles}
          imageResizeTargetWidth={200}
          imageResizeTargetHeight={144}
          imageResizeUpscale={false}
          imageResizeMode={"contain"}
        />
        {/* <TextField
          required
          id="outlined-required"
          label="Title"
          value={Title}
          onChange={(e) => setTitle(e.target.value)}
        /> */}
        <br />
        <br />
        <Button variant="contained" onClick={save}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Background;
