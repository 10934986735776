import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { context } from '../../App';

const ProtectedRoutes = () => {
    const [isAuth] = React.useContext(context);

  return (
    isAuth ? <Outlet /> : <Navigate to="/" />
  )
}

export default ProtectedRoutes