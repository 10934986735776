import React from 'react';
import './App.css';
import AppRoutes from './Componenents/Variables/AppRoutes';

export const context = React.createContext();

function App() {
  const [isAuth, setIsAuth] = React.useState(false);
  
  return (
      <context.Provider value={[isAuth, setIsAuth]}>
        <AppRoutes />
      </context.Provider>
  );
}

export default App;
