import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Product from "../Home/Product";
import Login from "../Login/Login";
import Background from "../CPanel/Background";
import Story from "../CPanel/Story";
import Categories from "../CPanel/Categories";
import Products from "../CPanel/Products";
import Locations from "../CPanel/Locations";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "../CPanel/Profile";

const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/Home/Product/:categoryId" element={<Product />} />

          <Route path="/cpanel/login" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/cpanel/header" element={<Background />} />
            <Route path="/cpanel/story" element={<Story />} />
            <Route path="/cpanel/categories" element={<Categories />} />
            <Route path="/cpanel/products" element={<Products />} />
            <Route path="/cpanel/locations" element={<Locations />} />
            <Route path="/cpanel/profile" element={<Profile />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
