import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="bg-footer">
          <div className="content">
            <div className="socialmediaF">
              <ul className="clearfix">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100093001530594" rel="nofollow" target="_blank">
                    <li className="fb"></li>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/menelday3a.lebneniye/" rel="nofollow" target="_blank">
                    <li className="in"></li>
                  </a>
                </li>
              </ul>
            </div>
            <p className="copyright">
              © Men El Dayaa {currentYear} - DESIGNED AND DEVELOPED BY{" "}
              <a href="https://gmrlgroup.com">Gray mackenzie Lebanon</a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
