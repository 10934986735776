import React, { useState } from "react";
import Constant from "../../Config/Constant";

//REQUEST
import Request from "../../Config/Request";

import spinnyesLogo from "../../assets/Images/SpinneysLogo.png";
import happyLogo from "../../assets/Images/Happy-logo.png";
import grabngoLogo from "../../assets/Images/grablogo.png";

function AccordionHeader({ tabs }) {
  const request = new Request();

  const [Location, setLocation] = React.useState();
  const [activeTab, setActiveTab] = useState(1);

  React.useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    const response = await request.getLocation();
    setLocation(response.data);
  };

  return (
    <div className="accordion-container">
        <div key={Location && Location.image1}>
          <div
            className={`accordion-header ${
              Location && activeTab === Location.image1 ? "active" : ""
            }`}
            onClick={() =>{setActiveTab(Location.image1);}}
          >
            <span className="accordion-header-icon"></span>
            Spinneys
          </div>
          <div
            className={`accordion-menu ${Location && activeTab === Location.image1 ? "open" : ""}`}
            style={{
              maxHeight: Location && activeTab === Location.image1 ? "500px" : "0",
            }}
          >
            <ul className="accordion-content englob-country ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ui-accordion-content-active">
              <li>
                <div style={{ backgroundColor: "#f8efe4;" }}>
                  {/* <div className="mapAcc">
                    <img
                      className="comAccImage"
                      src={Location && `${Constant.serverlink}${Location.image1}`}
                      style={{ marginLeft: "25px" }}
                    />
                  </div> */}
                  <div className="info-country-acc">
                    <div className="englob-country">
                      <img className="comLogo" src={spinnyesLogo} />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div key={Location && Location.image2}>
          <div
            className={`accordion-header ${
              Location && activeTab === Location.image2 ? "active" : ""
            }`}
            onClick={() =>{setActiveTab(Location.image2);}}
          >
            <span className="accordion-header-icon"></span>
            Happy
          </div>
          <div
            className={`accordion-menu ${Location && activeTab === Location.image2 ? "open" : ""}`}
            style={{
              maxHeight: Location && activeTab === Location.image2 ? "500px" : "0",
            }}
          >
            <ul className="accordion-content englob-country ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ui-accordion-content-active">
              <li>
                <div style={{ backgroundColor: "#f8efe4;" }}>
                  {/* <div className="mapAcc">
                    <img
                      className="comAccImage"
                      src={Location && `${Constant.serverlink}${Location.image2}`}
                      style={{ marginLeft: "25px" }}
                    />
                  </div> */}
                  <div className="info-country-acc">
                    <div className="englob-country">
                      <img className="comLogo" src={happyLogo} />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div key={Location && Location.image3}>
          <div
            className={`accordion-header ${
              Location && activeTab === Location.image3 ? "active" : ""
            }`}
            onClick={() =>{setActiveTab(Location.image3);}}
          >
            <span className="accordion-header-icon"></span>
            Grabn'Go
          </div>
          <div
            className={`accordion-menu ${Location && activeTab === Location.image3 ? "open" : ""}`}
            style={{
              maxHeight: Location && activeTab === Location.image3 ? "500px" : "0",
            }}
          >
            <ul className="accordion-content englob-country ui-accordion-content ui-helper-reset ui-widget-content ui-corner-bottom ui-accordion-content-active">
              <li>
                <div style={{ backgroundColor: "#f8efe4;" }}>
                  {/* <div className="mapAcc">
                    <img
                      className="comAccImage"
                      src={Location && `${Constant.serverlink}${Location.image3}`}
                      style={{ marginLeft: "25px" }}
                    />
                  </div> */}
                  <div className="info-country-acc">
                    <div className="englob-country">
                      <img className="comLogo" src={grabngoLogo} />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
    </div>
  );
}

export default AccordionHeader;
