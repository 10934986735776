import React, { useState, useEffect } from "react";
import Constant from "../../Config/Constant";
import Request from "../../Config/Request";
import spinnyesLogo from "../../assets/Images/spinneys.png";
import happyLogo from "../../assets/Images/happy.png";
import grabngoLogo from "../../assets/Images/GNG.png";

function Tabs() {
  const request = new Request();

  const [Location, setLocation] = React.useState([]);
  const [activeTab, setActiveTab] = useState(1);

  React.useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    const response = await request.getLocation();
    setLocation(response.data);
    setActiveTab(response.data[0]?.image);
  };

  return (
    <section id="stockists">
      <div className="content">
        <h2>Find us</h2>
        <div className="tabs">
          <ul>
            {Location && Location.map((location) => (
              <li
                key={location.image}
                onClick={() => setActiveTab(location.image)}
                className={activeTab === location.image ? "active" : ""}
              >
                {location.title}
              </li>
            ))}
          </ul>
          <div style={{ height: "500px", backgroundColor: "#f8efe4;" }}>
            <div className="info-country">
              <div className="englob-country">
                {Location && Location.map((location) => (
                  activeTab === location.image && (
                    <img key={location.image} className="comLogo" src={`${Constant.serverlink}${location.image}`} alt={location.title} />
                  )
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
