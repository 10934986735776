import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AdminAppBar from "../Admin/AdminAppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

import axios from "axios";

//REQUEST
import Request from "../../Config/Request";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import Constant from "../../Config/Constant";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const Locations = () => {
  const request = new Request();
  
  const [Location, setLocation] = React.useState();
  const [scroll, setScroll] = React.useState("paper");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sliders, setSliders] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [Title, setTitle] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
 


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    getLocation();
  }, []);

  const handleClickOpen = (scrollType) => () => {
 
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClickOpenDialog = (item) => {
    setSelectedImage(item);
    setOpenDialog(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    setIsLoading(true);
    const response = await request.getLocation();
    setSliders(response.data);
    setIsLoading(false);
  };
  
  const handleClose = () => {
    setSelectedImage(null);
    setTitle("");
    // setDescription("");
    setFiles([]);
    setOpen(false);
  };

  const deleteLocation = async () => {
    setIsLoading(true);
    const id = selectedImage.locationId;
    const response = await request.deleteLocation(id);
    if (response.status === 200) {
      setAlertMessage(`${selectedImage.title} has been deleted.`);
      setSeverity("success");
      setOpenAlert(true);
    } else {
      setAlertMessage("Something went wrong. Please try again.");
      setSeverity("error");
      setOpenAlert(true);
    }
    getLocation();
    setIsLoading(false);
    setOpenDialog(false);
    handleClose();
  };
 
  const save = async () => {
    if (Title === null || Title === "") {
      setAlertMessage("Please Enter Title.");
      setSeverity("error");
      setOpenAlert(true);
    }
    
    else if (selectedImage === null && files.length <= 0) {
      setAlertMessage("Please Upload Image.");
      setSeverity("error");
      setOpenAlert(true);
    } else {
      setIsLoading(true);
      var BearerToken = localStorage.getItem("access-token");

      const config = {
        headers: {
          Authorization: `Bearer ${BearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      let formData = new FormData();
      for (let key in files) {
        formData.append("File1", files[key].file);
      }
      if(selectedImage !== null) {
        formData.append("locationId", selectedImage.locationId);
        
      }
      formData.append("Title", Title);

      axios
        .post(`${Constant.serverlink}/api/Location/Location`, formData, config)
        .then((response) => {
          if (response.status === 200) {
            if (selectedImage !== null) {

              setAlertMessage("Location has been updated.");
            }
            else {
              setAlertMessage("Location has been added.");
            }
          
            setSeverity("success");
            setOpenAlert(true);
            setFiles([]);
            getLocation();
            setIsLoading(false);
            handleClose()
          } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong. Please try again.");
            setSeverity("error");
            setOpenAlert(true);
          }
          setOpen(false);
          setTitle("");
          
          setFiles([]);
        });
    }
  };

  return (
    <div>
         <AdminAppBar />
    <div className="categoryBtn">
 
        <Button variant="contained" onClick={handleClickOpen("paper")}>
          Add New
        </Button>
      </div>
      <table className="categoryTable" border="1">
        <tr>
          <th style={{ width: "20%" }}>#</th>
          <th style={{ width: "30%" }}>Title</th>
          <th style={{ width: "30%" }}>Image</th>
          <th>Actions</th>
        </tr>
        {sliders.map((item, index) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>
                <img
                  src={`${Constant.serverlink}${item.image}`}
                  style={{ width: "200px", height: "200px" }}
                />
              </td>
              <td>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setSelectedImage(item);
                    setTitle(item.title);
                    // setDescription(item.description);
                    setOpen(true);
                    console.log(item);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleClickOpenDialog(item)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          );
        })}
      </table>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        PaperProps={{
          style: {
            width: "100%",
            height: "70%",
          },
        }}
        aria-labelledby="scroll-dialog-title"
        // aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add New location</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {selectedImage !== null && (
              <img src={`${Constant.serverlink}${selectedImage.image}`} style={{width: "100%", height: "300px", marginBottom:"10px"}} />
            )}
            <FilePond
              files={files}
              allowReorder={true}
              allowMultiple={false}
              onupdatefiles={setFiles}
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={144}
              imageResizeUpscale={false}
              imageResizeMode={"contain"}
            />
            <TextField
              required
              id="outlined-required"
              label="Title"
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={save}>
            {selectedImage !== null ? <>Update</> : <>Add</>}
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedImage !== null ? (
              <p>Are You sure you want to delete {selectedImage.title}? </p>
            ) : (
              <p>Are You sure you want to delete this item?</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={deleteLocation}>Delete</Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Locations