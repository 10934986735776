import React, { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper-bundle.min.css";
import Constant from "../../Config/Constant";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Slider2 = ({ categories }) => {
  const isMobile = window.innerWidth <= 500;
  const isMediumScreen = window.innerWidth <= 768;
  const swiperRef = useRef(null);

  const handleSwiperInit = (swiper) => {
    swiperRef.current = swiper;
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  }, [swiperRef]);

  useEffect(() => {
    enableSwiperNavigation();
  }, []);

  const enableSwiperNavigation = () => {
    const nextButton = document.querySelector('.swiper-button-next');
    const prevButton = document.querySelector('.swiper-button-prev');

    if (nextButton && prevButton) {
      nextButton.addEventListener('click', function () {
        swiperRef.current.slideNext();
      });

      prevButton.addEventListener('click', function () {
        swiperRef.current.slidePrev();
      });
    }
  };

  return (
    <div>
      <h2>Our Categories</h2>
      <div className="slider-container2">
        <Swiper
          slidesPerView={isMobile ? 1 : isMediumScreen ? 2 : 3}
          spaceBetween={5}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          // loop={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
          onInit={handleSwiperInit}
        >
          {categories.map((item) => (
            <SwiperSlide key={item.sliderId}>
            <Link to={`/Home/Product/${item.categoryId}`}>
              <img
              src={`${Constant.serverlink}${item.image}`}
            alt={`Image ${item.image}`}
          />
          <h1 className="overlay-text">
            <span>{item.title}</span>
          </h1>
          </Link>
        </SwiperSlide>
          ))}

          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </Swiper>
      </div>
    </div>
  );
};
export default Slider2;