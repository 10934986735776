import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

const DetailsThumb = forwardRef(({ images, tab, onThumbClick }, ref) => {
  const [initialIndex, setInitialIndex] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const thumbnails = ref.current.children;
      if (thumbnails && thumbnails[initialIndex]) {
        thumbnails[initialIndex].classList.add('active');
      }
    }

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        const newIndex =
          event.key === 'ArrowLeft'
            ? (initialIndex - 1 + images.length) % images.length
            : (initialIndex + 1) % images.length;

        setThumbInitialIndex(newIndex);
        onThumbClick(newIndex);
      }
    };

    // Add event listener for keyboard arrow keys
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ref, initialIndex, images, onThumbClick]);

  const handleClick = (index) => {
    tab(index);
    setThumbInitialIndex(index);

    if (ref.current) {
      const thumbnails = ref.current.children;
      if (thumbnails) {
        for (let i = 0; i < thumbnails.length; i++) {
          thumbnails[i].classList.remove('active');
        }

        if (thumbnails[index]) {
          thumbnails[index].classList.add('active');
        }
      }
    }

    // Notify the parent component about the thumb click
    onThumbClick(index);
  };

  // Define a function to set the initial index
  const setThumbInitialIndex = (index) => {
    setInitialIndex(index);
  };

  useImperativeHandle(ref, () => ({
    setInitialIndex: (index) => {
      setThumbInitialIndex(index); // Call the correct function here
    },
  }));

  return (
    <div className="thumb" ref={ref}>
      {images.map((img, index) => (
        <img
          src={img}
          alt=""
          key={index}
          onClick={() => handleClick(index)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick(index);
            }
          }}
          className={index === initialIndex ? 'active' : ''}
          tabIndex={0} // Make the image focusable
        />
      ))}
    </div>
  );
});

export default DetailsThumb;
