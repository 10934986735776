import React, { useState, useEffect } from "react";
import AdminAppBar from "../Admin/AdminAppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

//material select 
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Pagination } from '@mui/material';

import Search from '../ProductComponent/Search';
import axios from "axios";

//REQUEST
import Request from "../../Config/Request";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import Constant from "../../Config/Constant";
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);




const Products = () => {

  const request = new Request();


  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");

// ***************************************************************************************
const [currentPage, setCurrentPage] = React.useState(1);
const [totalPages, setTotalPages] = React.useState(1);

const [products, setProducts] = React.useState([]);
const [itemsPerPage, setItemsPerPage] = React.useState(10);

// ***************************************************************************************


  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [files, setFiles] = React.useState([]);
  const [Title, setTitle] = React.useState("");

  const [category, setCategory] = React.useState([]); 
  const [selectedCategory, setSelectedCategory] = React.useState("");

  const [Description, setDescription] = React.useState("");
  const [Size, setSize] = React.useState("");
  const [sliders, setSliders] = React.useState([]);
  const [selectedSlider, setSelectedSlider] = React.useState(null);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const GetCategory = async () => {
    try {
      const response = await request.GetCategory();
      setCategory(response.data); // Corrected name
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  React.useEffect(() => {
    GetCategory();
  }, []); 



  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setSelectedSlider(null);
    setTitle("");
    setDescription("");
    setSize("");
    setSelectedCategory("");
    setFiles([]);
    setOpen(false);
  };


  
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const handleClickOpenDialog = (item) => {
    setSelectedSlider(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };




 

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const deleteProducts = async () => {
    setIsLoading(true);
    const id = selectedSlider.productId;
    const response = await request.deleteProducts(id);
  
    if (response.status === 200) {
      setAlertMessage(`The Product has been deleted.`);
  
      setSeverity("success");
      setOpenAlert(true);
    } else {
      setAlertMessage("Something went wrong. Please try again.");
      setSeverity("error");
      setOpenAlert(true);
    }
  
    // If you still want to call GetProducts, do it after updating the state
    GetProducts({ page: currentPage, search: searchTerm });
  
    setIsLoading(false);
    setOpenDialog(false);
    handleClose();
  };

  const save = async () => {
    // console.log('Save function is called');

    // if (Description === null || Description === "") {
    //     setAlertMessage("Please Enter Description.");
    //     setSeverity("error");
    //     setOpenAlert(true);
    // }
    // else 
    if (Title === null || Title === "") {
      setAlertMessage("Please Enter Title.");
      setSeverity("error");
      setOpenAlert(true);
    }
    else if (Size === null || Size === "") {
      setAlertMessage("Please Enter Size.");
      setSeverity("error");
      setOpenAlert(true);
    } 
    else if (selectedSlider === null && files.length <= 0) {
      setAlertMessage("Please Upload Image.");
      setSeverity("error");
      setOpenAlert(true);
    }
    else if (selectedCategory  === null || selectedCategory === "") {
      setAlertMessage("Please Select Category.");
      setSeverity("error");
      setOpenAlert(true);
    }
     else {
      setIsLoading(true);
      var BearerToken = localStorage.getItem("access-token");

      const config = {
        headers: {
          Authorization: `Bearer ${BearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      let formData = new FormData();
      for (let key in files) {
        formData.append("File", files[key].file);
      }
      if(selectedSlider !== null) {
        formData.append("productId", selectedSlider.productId);
      }
      formData.append("Title", Title);
      formData.append("Description", Description);
      formData.append("Size", Size);
      formData.append("CategoryId", selectedCategory);
      axios
        .post(`${Constant.serverlink}/api/Products/Products`, formData, config)
        .then((response) => {
          // if (response.status === 200) {
          //   if (selectedSlider !== null) {

          //     setAlertMessage("Product has been updated.");

          //   }
          //   else {
          //     setAlertMessage("Product has been added.");
          //   }
          if (response.status === 200) {
            if (selectedSlider !== null) {
              setAlertMessage("Product has been updated.");
              // Update the local state for the updated product
              setSliders((prevSliders) => {
                const updatedSliders = prevSliders.map((slider) =>
                  slider.productId === selectedSlider.productId
                    ? { ...slider, title: Title, size: Size, description: Description, category: { categoryId: selectedCategory, title: selectedSlider.category.title } }
                    : slider
                );
                return updatedSliders;
              });
            } else {
              setAlertMessage("Product has been added.");
              GetProducts({ page: currentPage, search: searchTerm });
            }
            setSeverity("success");
            setOpenAlert(true);
            setFiles([]);
             // GetProducts();
            // GetProducts({ page: currentPage, search: searchTerm });
            setIsLoading(false);
            handleClose();
          } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong. Please try again.");
            setSeverity("error");
            setOpenAlert(true);
          }
          setOpen(false);
          setSize("");
          setDescription("");
          setSelectedCategory("");
          setFiles([]);
        });
    }
  };

  // React.useEffect(() => {
  // }
//   const filtered = sliders.filter((item) =>
//   (item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
//   (item.category.title && item.category.title.toLowerCase().includes(searchTerm.toLowerCase()))
// );
  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
    setCurrentPage(1); 
    // //setFilteredProducts(filtered);
  
  };


  const GetProducts = async ({ page, search }) => {
    setIsLoading(true);
    try {
    const response = await request.GetProducts(`?page=${page}&search=${search}`);
      console.log('Response:', response);
  
      if (
        response.data &&
        response.data.totalPages !== undefined &&
        response.data.currentPage !== undefined &&
        response.data.products !== undefined
      ) {
        const { totalPages, currentPage, products } = response.data;
  
        const validPage = Math.max(1, Math.min(currentPage, totalPages));
  
        console.log("totalPages", totalPages);
        console.log("currentPage", currentPage);
        console.log("validPage", validPage);


// Filter products based on search term
// const filtered = searchTerm ? products: products;
// console.log("filtered",filtered)

  
        // if (totalPages !== undefined && currentPage !== undefined && products !== undefined) {
          setSliders(products);
          //setSliders(products);
          setTotalPages(totalPages);
          setCurrentPage(validPage);
        // }
        // setFilteredProducts(filtered);
       
      } else {
        console.error("Invalid response format:", response);
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
    }
  };
  

  React.useEffect(() => {
    const fetchData = async () => {
     await GetProducts({ page: currentPage ,search: searchTerm});
    };

    fetchData();
  }, [currentPage, searchTerm]);

  
  const handlePageChange = (event, newPage) => {
    console.log("Changing to page:", newPage);
    setCurrentPage(newPage);
    // Call GetProducts with the newPage
    GetProducts({ page: newPage,search: searchTerm });
  };



  return (
    <div>
      <AdminAppBar />
      <div className="categoryBtn">
        <Button variant="contained" onClick={handleClickOpen("paper")}>
          Add New
        </Button>

         <Search onSearch={handleSearch} />
      </div>
      <table className="categoryTable" border="1">
       <thead>
         <tr>
            <th style={{ width: "5%" }}>#</th>
            <th style={{ width: "20%" }}>Category name</th>
            <th style={{ width: "30%" }}>Product name</th>
            <th style={{ width: "20%" }}>Product size</th>
            <th style={{ width: "25%" }}>Image</th>
            <th>Actions</th>
        </tr>
      </thead>
        
     <tbody>
      {/* {filteredProducts.map((item, index) => ( */}
      {sliders.map((item, index) => (
       < tr key={item.productId}>
           <td>{index + 1}</td>
           <td>{item.category.title}</td>
           <td>{item.title}</td>
           <td>{item.size}</td>
           <td><img src={`${Constant.serverlink}${item.image}`}style={{ width: "200px", height: "200px" }}alt={`Product ${index + 1}`} /></td>
           <td>
               <Button
               variant="outlined"
               startIcon={<EditIcon />}
               onClick={() => {
                   setSelectedSlider(item);
                   setTitle(item.title);
                   setSize(item.size);
                   setDescription(item.description);
                   setSelectedCategory(item.category.categoryId);
                   setOpen(true);}}
                > Edit</Button>
               <Button
               variant="outlined"
               startIcon={<DeleteIcon />}
               onClick={() => handleClickOpenDialog(item)}
                > Delete</Button>
           </td>
        </tr> ))}
     </tbody>
      </table>
      <Pagination
  count={totalPages}
  page={currentPage}
  onChange={handlePageChange}
  color="primary"
  style={{ marginTop: '30px' ,marginBottom:'30px',marginRight:'149px',float:'right'}}
/>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        PaperProps={{
          style: {
            width: "100%",
            height: "70%",
          },
        }}
        aria-labelledby="scroll-dialog-title"
        // aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add New Product</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {selectedSlider !== null && (
              <img src={`${Constant.serverlink}${selectedSlider.image}`} style={{width: "100%", height: "300px", marginBottom:"10px"}} />
            )}
            <FilePond
              files={files}
              allowReorder={true}
              allowMultiple={false}
              onupdatefiles={setFiles}
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={144}
              imageResizeUpscale={false}
              imageResizeMode={"contain"}
            />
            <TextField
              required
              id="outlined-required"
              label="Title"
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
            />
             <br />
            <br />
              <TextField
              required
              id="outlined-required"
              label="Size"
              value={Size}
              style={{marginBottom:'10px'}}
              onChange={(e) => setSize(e.target.value)}
            />
            <br />
            <br />
            <TextField
              required
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <br />
            <br />
            <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedCategory}
          label="Category"
          onChange={handleChange}
        >
          {category.map((category) => (
            <MenuItem key={category.categoryId} value={category.categoryId}>
              {category.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={save}>
            {selectedSlider !== null ? <>Update</> : <>Add</>}
           
          </Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedSlider !== null ? (
              <p>Are You sure you want to delete this item ?</p>
            ) : (
              <p>Are You sure you want to delete this item?</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={deleteProducts}>Delete</Button>
        </DialogActions>
      </Dialog>

      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Products;
