import React, { useState } from "react";
import axios from "axios";



//COMPONENTS
import Slider2 from "../Slider/Slider2";
import Tabs from "../Tabs/Tabs";
import Accordion from "../Accordion/Accordion";

//REQUEST
import Request from "../../Config/Request";

//MATERIAL UI
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//IMAGES
import spinnyesImage from "../../assets/Images/spinneys.jpg";
import spinnyesLogo from "../../assets/Images/SpinneysLogo.png";
import happyImage from "../../assets/Images/happyIm.jpg";
import happyLogo from "../../assets/Images/Happy-logo.png";
import grabngoImage from "../../assets/Images/grabngo.jpg";
import grabngoLogo from "../../assets/Images/grablogo.png";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Constant from "../../Config/Constant";

const tabs = [
  {
    id: 1,
    title: "Spinneys",
    image: spinnyesImage,
    logo: spinnyesLogo,
  },
  {
    id: 2,
    title: "Happy",
    image: happyImage,
    logo: happyLogo,
  },
  {
    id: 3,
    title: "Grabn'Go",
    image: grabngoImage,
    logo: grabngoLogo,
  },
];

const Home = () => {
  const request = new Request();

  const [name, setName] = useState("");
  const [from, setFrom] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [categoryslider, setCategorySlider] = React.useState([]);
  const [HeaderData, setHeaderData] = React.useState();
  const [Story, setStory] = React.useState();
  

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    getHeader();
    getStory();
    getCategory();
  }, []);

  const getHeader = async () => {
    const response = await request.getHeader();
    setHeaderData(response.data);
  };

  const getStory = async () => {
    const response = await request.getStory();
    setStory(response.data);
  };

  const getCategory = async () => {
    const response = await request.GetCategory();
    setCategorySlider(response.data);
  };

  

  const sendMessage = async () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberPattern = /^\d+$/;

    if (name === null || name === "") {
      setAlertMessage("Please Enter Your Name.");
      setSeverity("error");
      setOpen(true);
    } else if (from === null || from === "") {
      setAlertMessage("Please Enter Your Email.");
      setSeverity("error");
      setOpen(true);
    } else if (message === null || message === "") {
      setAlertMessage("Please Enter Your Message.");
      setSeverity("error");
      setOpen(true);
    } else if (!emailPattern.test(from)) {
      setAlertMessage("Please Enter a valid Email Address.");
      setSeverity("error");
      setOpen(true);
    } else if (!phoneNumberPattern.test(number)) {
      setAlertMessage("Please Enter a valid Phone Number.");
      setSeverity("error");
      setOpen(true);
    } else {
      setIsLoading(true);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      };

      let formData = new FormData();
      formData.append("name", name);
      formData.append("from", from);
      formData.append("number", number);
      formData.append("message", message);
      axios
        .post(
          `${Constant.serverlink}/api/Contact`,
          formData,
          config,
        )
        .then((response) => {
          if (response.status === 200) {
            setName("");
            setFrom("");
            setNumber("");
            setMessage("");
            setAlertMessage("Thank you for contacting us, Our HR department will get back to you as soon as possible.");
            setSeverity("success");
            setOpen(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong. Please try again.");
            setSeverity("error");
            setOpen(true);
          }
        });
    }
  };

  return (
    <>
      <div>
        <Header Data={HeaderData} />
        <section id="ourstory" className="our-story">
          <div className="content">
            <h2>OUR STORY</h2>
            <blockquote>
              {Story && Story.description}
            </blockquote>
          </div>
        </section>

        <section className="row backImage">
          <div
            className="col-lg-4 col-md-4 col-sm-12"
            style={{ paddingRight: "0", paddingLeft: "0" }}
          >
            <img src={Story && `${Constant.serverlink}${Story.image1}`}  />
          </div>

          <div
            className="col-lg-4 col-md-4 col-sm-12"
            style={{ paddingRight: "0", paddingLeft: "0" }}
          >
            <img src={Story && `${Constant.serverlink}${Story.image2}`}  />
          </div>

          <div
            className="col-lg-4 col-md-4 col-sm-12"
            style={{ paddingRight: "0", paddingLeft: "0" }}
          >
            <img src={Story && `${Constant.serverlink}${Story.image3}`}  />
          </div>
        </section>




        <section id="ourrange">
          <Slider2 categories={categoryslider} />
        </section>
        
        <Tabs  />
        <Accordion tabs={tabs} />

        <section id="contactus">
          <div className="content ">
            <h2>Contact Us</h2>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 ">
                <h4>
                  <span>Your Name*</span>
                </h4>
                <form className="formIn">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    aria-required="true"
                    aria-invalid="false"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </form>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <h4>
                  <span>Email*</span>
                </h4>
                <form className="formIn">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    aria-required="true"
                    aria-invalid="false"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                  />
                </form>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <h4>
                  <span>Phone Number</span>
                </h4>
                <form className="formIn">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    aria-required="true"
                    aria-invalid="false"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </form>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 textArea">
                <h4>
                  <span>Your Message*</span>
                </h4>
                <textarea
                  name="Message"
                  cols="40"
                  rows="10"
                  id="message"
                  aria-required="true"
                  aria-invalid="false"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <fieldset className="button">
                  <input
                    type="submit"
                    value="Send"
                    className="wpcf7-form-control wpcf7-submit"
                    onClick={sendMessage}
                  />
                </fieldset>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>

      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Home;
