import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import '../../assets/css/App.css';
import DetailsThumb from '../ProductComponent/DetailsThumb';
import Request from "../../Config/Request";
import Constant from "../../Config/Constant";

//
import SwiperCore, { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

// Install Swiper modules
SwiperCore.use([Navigation, A11y, Autoplay]);

const Product = () => {

  const {categoryId} = useParams();
  const [products, setProducts] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const request = new Request();
  const swiperRef = useRef(null);
  const detailsThumbRef = useRef(null);






  const handleTab = (index) => {
    setCurrentProductIndex(index);
  };

  const handleThumbClick = (index) => {
    setCurrentProductIndex(index);

    if (swiperRef.current) {
      swiperRef.current.slideTo(index); // Manually update Swiper's active index
    }
  };

  useEffect(() => {
    fetchProductsByCategory();
  }, []);

  
  const fetchProductsByCategory = async () => {
    try {
      const response = await request.getProductsByCategory(categoryId);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const currentProduct = products[currentProductIndex];

  const handleSlideChange = (swiper) => {
    // Update the current product index when the slide changes
    setCurrentProductIndex(swiper.activeIndex);

    // Update the active thumbnail in the DetailsThumb component
    if (detailsThumbRef.current && detailsThumbRef.current.setInitialIndex) {
      detailsThumbRef.current.setInitialIndex(swiper.activeIndex);
    }

  };






  return (
    <div className="app">
      <Link to="/" className="back-link">
        <AiOutlineClose className="back-icon" />
        <span className="back-button">Back</span>
      </Link>

      <div className="details mediaQuery">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation
          onSlideChange={handleSlideChange}
          ref={swiperRef}
          onSwiper={(swiper) => {
            swiperRef.current = swiper; // Assign the Swiper instance to the ref
          }}
        >
          {products.map((product) => (
            <SwiperSlide key={product.productId}>
              <div className="big-img">
                <img src={`${Constant.serverlink}${product.image}`} alt="" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="box">
          <div className="row">
            <h2>{currentProduct?.title}</h2>
          </div>
          <p style={{color:'red'}}>size: {currentProduct?.size}</p>
          <p>{currentProduct?.description}</p>
          <DetailsThumb
             images={products.map((p) => `${Constant.serverlink}${p.image}`)}
            tab={handleTab}
            ref={detailsThumbRef} // Use a separate ref for DetailsThumb
            onThumbClick={handleThumbClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
