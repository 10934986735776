import React, { useState, useEffect } from "react";
import logo from "../../assets/Images/menelday3a.png";
import logo1 from "../../assets/Images/min_dayaa_logo.png";
import souris from "../../assets/Images/souris.png";
import { animateScroll as scroll } from "react-scroll";
import Constant from "../../Config/Constant";
import IconButton from "@mui/material/IconButton";
import DehazeIcon from "@mui/icons-material/Dehaze";

const scrollToElement = (id, offset = 0) => {
  const elementOffset = document.getElementById(id).offsetTop + offset;
  scroll.scrollTo(elementOffset, {
    duration: 500, // Duration of an
    delay: 100, // Delay before the animation starts in /ms
    smooth: "easeInOutQuart", // Type of animation easing
  });
};

function Header({ Data }) {
  const isMobile = window.innerWidth <= 500;

  const [isFixed, setIsFixed] = useState(false);
  const [isFixed1, setIsFixed1] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 600) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 500) {
        setIsFixed1(true);
      } else {
        setIsFixed1(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ zIndex: -1 }} id="page" className="hfeed site">
      <div
        id="HeaderSection"
        style={{
          backgroundImage: Data && `url(${Constant.serverlink}${Data.image})`,
          backgroundSize: "cover", // You can adjust the background size as needed
          backgroundPosition: "center", // You can adjust the background position as needed
        }}
      >
        {/* <header className={isFixed ? "fixed " : ""}> */}
        <header className={isFixed ? "fixed " : "fixed"}>
          <div className="header-wrap" id="header">
            <nav className="menu">
              <ul className="clearfix">
                <div className="row headerLogo">
                  <div className="col-md-12">
                    <img className="logo-img" src={logo} />
                  </div>
                </div>
                <li>
                  <a
                    className="DNone"
                    href="#"
                    onClick={() => scrollToElement("ourstory", -70)}
                  >
                    Our Story
                  </a>
                </li>
                <li>
                  <a
                    className="DNone"
                    href="#"
                    onClick={() => scrollToElement("ourrange", -70)}
                  >
                    Our Categories
                  </a>
                </li>

                <li className="logo">
                  <a href="#header">
                    <img className="logo-img DNone" src={logo} />
                    <img className="logo1" src={logo1} />
                  </a>
                </li>
                <li>
                  <a
                    className="DNone"
                    href="#"
                    onClick={() => scrollToElement("stockists", -70)}
                  >
                    Find Us
                  </a>
                </li>
                <li>
                  <a
                    className="DNone"
                    href="#"
                    onClick={() => scrollToElement("contactus", -70)}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        {/*<h1 className="textHeader">
          {Data && Data.title}
        </h1>*/}

        <a
          href="#"
          onClick={() => scrollToElement("contactus", -70)}
          className="scroll-down "
        >
          <img src={souris} />
        </a>
        {!isMobile && (
          <div className="header1">
            <div className={isFixed1 ? "fixed1" : ""}>
              <div className="header-wrap" id="header">
                <nav className="menu">
                  <ul className="clearfix">
                    <li className="DNone">
                      <a
                        href="#"
                        onClick={() => scrollToElement("ourstory", -70)}
                      >
                        Our Story
                      </a>
                    </li>
                    <li className="DNone">
                      <a
                        href="#"
                        onClick={() => scrollToElement("ourrange", -70)}
                      >
                        Our Categories
                      </a>
                    </li>

                    <li className="DNone">
                      <a
                        href="#"
                        onClick={() => scrollToElement("stockists", -70)}
                      >
                        Find Us
                      </a>
                    </li>
                    <li className="DNone">
                      <a
                        href="#"
                        onClick={() => scrollToElement("contactus", -70)}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}

{isMobile && (
  <div className={`mobileHeader ${isDropdownOpen ? 'open' : ''}`}>
    <div className="mobileHeaderTitle">
      <div>
        <img className="logo-img" src={logo1} />
      </div>
      <IconButton
        size="large"
        aria-label="show more"
        aria-haspopup="true"
        color="inherit"
        onClick={toggleDropdown}
      >
        <DehazeIcon />
      </IconButton>
    </div>

    <div className="mobileHeaderItem">
      <a href="#" onClick={() => {setIsDropdownOpen(false); scrollToElement("ourstory", -70);}}>our story</a>
      <hr />
      <a href="#" onClick={() => {setIsDropdownOpen(false); scrollToElement("ourrange", -70);}}>Our Categories</a>
      <hr />
      <a href="#" onClick={() => {setIsDropdownOpen(false); scrollToElement("stockists", -70);}}>Find Us</a>
      <hr />
      <a href="#" onClick={() => {setIsDropdownOpen(false); scrollToElement("contactus", -70);}}>Contact Us</a>
    </div>
  </div>
)}

      </div>
    </div>
  );
}

export default Header;
