import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AdminAppBar from "../Admin/AdminAppBar";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import axios from "axios";

//REQUEST
import Request from "../../Config/Request";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
import Constant from "../../Config/Constant";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
);

const Story = () => {
  const request = new Request();

  const [Story, setStory] = React.useState();
  const [files1, setFiles1] = React.useState([]);
  const [Image1, setImage1] = React.useState("");
  const [files2, setFiles2] = React.useState([]);
  const [Image2, setImage2] = React.useState("");
  const [files3, setFiles3] = React.useState([]);
  const [Image3, setImage3] = React.useState("");
  const [Description, setDescription] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    getStory();
  }, []);

  const getStory = async () => {
    setIsLoading(true);
    const response = await request.getStory();
    setStory(response.data);
    setImage1(response.data.image1);
    setImage2(response.data.image2);
    setImage3(response.data.image3);
    setDescription(response.data.description);
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const save = async () => {
    if (Description === null || Description === "") {
      setAlertMessage("Please Enter Title.");
      setSeverity("error");
      setOpen(true);
    } else {
      setIsLoading(true);
      var BearerToken = localStorage.getItem("access-token");

      const config = {
        headers: {
          Authorization: `Bearer ${BearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      let formData = new FormData();
      for (let key in files1) {
        formData.append("File1", files1[key].file);
      }
      for (let key in files2) {
        formData.append("File2", files2[key].file);
      }
      for (let key in files3) {
        formData.append("File3", files3[key].file);
      }
      formData.append("StoryId", Story.storyId);
      formData.append("Description", Description);
      axios
        .post(`${Constant.serverlink}/api/Story/Story`, formData, config)
        .then((response) => {
          if (response.status === 200) {
            setAlertMessage("Story has been updated.");
            setSeverity("success");
            setOpen(true);
            setFiles1([]);
            setFiles2([]);
            setFiles3([]);
            getStory();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setAlertMessage("Something went wrong. Please try again.");
            setSeverity("error");
            setOpen(true);
          }
        });
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AdminAppBar />
      <section className="row">
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          style={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <img
            src={`${Constant.serverlink}${Image1}`}
            style={{ width: "100%", height: "400px" }}
          />
          <div style={{ padding: "10px" }}>
            <FilePond
              files={files1}
              allowReorder={true}
              allowMultiple={false}
              onupdatefiles={setFiles1}
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={144}
              imageResizeUpscale={false}
              imageResizeMode={"contain"}
            />
          </div>
        </div>

        <div
          className="col-lg-4 col-md-4 col-sm-12"
          style={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <img
            src={`${Constant.serverlink}${Image2}`}
            style={{ width: "100%", height: "400px" }}
          />
          <div style={{ padding: "10px" }}>
            <FilePond
              files={files2}
              allowReorder={true}
              allowMultiple={false}
              onupdatefiles={setFiles2}
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={144}
              imageResizeUpscale={false}
              imageResizeMode={"contain"}
            />
          </div>
        </div>

        <div
          className="col-lg-4 col-md-4 col-sm-12"
          style={{ paddingRight: "0", paddingLeft: "0" }}
        >
          <img
            src={`${Constant.serverlink}${Image3}`}
            style={{ width: "100%", height: "400px" }}
          />
          <div style={{ padding: "10px" }}>
            <FilePond
              files={files3}
              allowReorder={true}
              allowMultiple={false}
              onupdatefiles={setFiles3}
              imageResizeTargetWidth={200}
              imageResizeTargetHeight={144}
              imageResizeUpscale={false}
              imageResizeMode={"contain"}
            />
          </div>
        </div>
      </section>
      <br />
      <div className="storyContent">
        <TextField
          required
          id="outlined-required"
          label="Description"
          multiline
          style={{width: "80%"}}
          value={Description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" onClick={save}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Story;
